import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <div className="section">
        <h2>Effective Date: 15/05/2023</h2>
      </div>

      <div className="section">
        <div className="section-title">1. Information We Collect</div>
        <p>
          1.1. Personal Information: The App may require access to your device's camera to capture images for text recognition and access to your device's microphone to record audio for speech-to-text functionality. We do not store or transmit any personal information derived from the images or audio captured by the camera or microphone.
        </p>
        <p>
          1.2. Non-Personal Information: We may collect non-personal information such as device information, operating system details, and usage statistics. This information is used to analyze and improve the App's performance and functionality.
        </p>
      </div>

      <div className="section">
        <div className="section-title">2. Use of Information</div>
        <p>
          2.1. Text Recognition: The App uses the camera to capture images for the purpose of recognizing text within the images. The recognized text is processed locally within the App and sent to our AI system to provide the desired functionality.
        </p>
        <p>
          2.2. Speech-to-Text: The App uses the microphone to record audio input for speech-to-text functionality. The recorded audio is processed locally within the App and sent to our AI system to convert it into text for the desired functionality.
        </p>
        <p>
          2.3. Internal Analytics: We may use non-personal information, such as usage statistics, to analyze user behavior, improve the App's features, and optimize user experience.
        </p>
      </div>

      <div className="section">
        <div className="section-title">3. Data Security</div>
        <p>
          3.1. We take appropriate measures to safeguard the security of user data. However, please note that no method of transmission or storage is 100% secure. We cannot guarantee the absolute security of your data but strive to implement industry-standard security practices.
        </p>
      </div>

      <div className="section">
        <div className="section-title">4. Data Sharing</div>
        <p>
          4.1. We do not share or sell any user data collected through the App with third parties for marketing or advertising purposes.
        </p>
        <p>
          4.2. We may share non-personal, aggregated information with trusted partners for statistical purposes or to improve our services.
        </p>
      </div>

      <div className="section">
        <div className="section-title">5. Children's Privacy</div>
        <p>
          5.1. The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child under 13, we will take steps to delete it promptly.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
